import { defineStore } from 'pinia'
import type { MFAVerify, PromiseAny, SignIn, SignUp, User } from '~/models'

export const useUserStore = defineStore('UserStore', {
  // USER STATE
  state: (): User => {
    return {
      auth_token: null,
      favorites: [],
      pinned_market: '',
      newAddress: {},
      transactions: {},
      lastWithdraw: {},
      alarmFilters: {},
      transaction: {},
      baseCurrency: '',
      notificationSettings: {},
      announcementSettings: {},
      FinancialHistory: {},
      FinancialHistoryMeta: {},
      historyFilters: {
        processes: [],
        currencies: [],
        started_at: '',
        ended_at: '',
        period: '30',
        page: 1,
        per_page: 25,
      },
      verifyPayload: {},
      email_token: '',
      forget_token: '',
      redirectRoute: {},
    }
  },

  // USER ACTIONS
  actions: {
    async signIn(payload: SignIn) {
      const data = await $http.post('auth/sign-in', payload)
      return data
    },

    addAuthToken(token: string): void {
      this.auth_token = token
    },

    fetchArticle({ article }: { article: string }): PromiseAny {
      return $fetch(`https://paribu.zendesk.com/api/v2/help_center/articles/${article}`)
    },

    signUp(payload: SignUp): PromiseAny {
      return $http.post('auth/sign-up', payload)
    },

    mfaVerify(payload: MFAVerify): PromiseAny {
      return $http.post('mfa/verify?reason=sign-in', payload)
    },

    mfaResend(payload: { token: string }): PromiseAny {
      return $http.post('mfa/resend', payload)
    },

    // signUpEmail(payload: SignUpEmail): PromiseAny {
    //   return $http('auth/sign-up-email', payload)
    // },

    // resetPassword(payload: ResetPassword): PromiseAny {
    //   return $http('auth/new-password', payload)
    // },
    // changePassword(payload: ChangePassword): PromiseAny {
    //   return $http('auth/change-password', payload)
    // },
    // forgotPassword(payload: ForgotPassword): PromiseAny {
    //   return $http('auth/forget', payload)
    // },
  },

  // USER GETTERS
  getters: {
    getAuthToken: async () => await localStorage.getItem('auth-token'),

    isAuthenticated: (state) => state.auth_token,
  },
})
