import { defineStore } from 'pinia'
import jsonmergepatch from 'json-merge-patch'
import Pusher from '@/plugins/pusher'
import type {
  MainState,
  NotifyResponse,
  CurrencyList,
  MarketConfig,
  MarketList,
  TickerList,
} from '~/models'

export const useMainStore = defineStore('MainStore', {
  // MAIN STATE
  state: (): MainState => {
    return {
      user: {},
      ticker: {},
      routeState: {},
      priceSeries: {},
      activeTour: '',
      snackbarList: [],
      isAppLoaded: false,
      isGlobal: false,
      activeTheme: 'light',
      dialogAttributes: {},
      bannerAttributes: {},
      isAssetVisible: false,
      maintenanceMode: false,
      compIdNotify: false,
      compIdStep: '',
      config: {
        networks: {},
        fee_matrix: {},
        markets: {},
        currencies: {},
        app_settings: {},
      },
    }
  },

  // MAIN ACTIONS
  actions: {
    setDialogAttributes(dialogAttributes: NotifyResponse) {
      this.dialogAttributes = dialogAttributes
    },

    resetDialogAttributes() {
      this.dialogAttributes = {}
    },

    init() {
      this.fetchInitialData()
      if (import.meta.client) {
        this.rootSubscriber()
      }
    },

    async fetchInitialData() {
      const baseUrl = process.env.NUXT_API_ASSET_BASE_URL
      const responses = (await Promise.all([
        $http.get('initials/config'),
        $http.get('initials/ticker/extended'),
        $http.get('initials/price-series'),
        // todo: add locale instead of tr.json
        $http.get(`${baseUrl}/locale/messages/tr.json`),
      ])) as any

      // this.isGlobal = responses[0]?.headers['x-country']
      this.config = responses[0]?.payload
      this.ticker = responses[1]?.payload
      this.priceSeries = responses[2]?.payload
      if (import.meta.client) {
        const authToken = localStorage.getItem('auth-token')
        if (authToken) {
          this.fetchUser()
        }
      }
      /*
      locale.global.setLocaleMessage(
        locale.global.locale,
        _.merge(locale.global.messages[locale.global.locale], responses[3]?.data),
      )
        */
    },

    rootSubscriber() {
      Pusher.subscribe({ channel: 'config', mutation: 'config' })
      Pusher.subscribe({ channel: 'ticker-extended', mutation: 'ticker' })
      //  Pusher.subscribe({ channel: 'notification', mutation: 'fireNotify' })
    },

    mergePusherData(stateKey: string, data: any) {
      jsonmergepatch.apply((this as any)[stateKey], data.payload)
    },

    fetchUser(): Promise<any> {
      return $http.get('user').then((res: any) => {
        this.user = res.data.payload
      })
    },
  },

  // MAIN GETTERS
  getters: {
    getDialogAttributes: (state: MainState) => state?.dialogAttributes?.message,

    getMarkets: (state: MainState): MarketList => {
      const currencies = Object.keys(state.config?.currencies || {})
      const markets = $_.pickBy(state.config?.markets, (market: MarketConfig) => {
        return (
          !$_.isEmpty(market) &&
          $_.isObject(market) &&
          !market?.suspended &&
          currencies.includes(String(market?.pairs?.market))
        )
      })
      return markets
    },

    getTickers: (state: MainState): TickerList => state.ticker as TickerList,

    getPriceSeries: (state: MainState) => state.priceSeries,

    getAllMarkets: (state: MainState): MarketList => {
      const currencies = Object.keys(state.config?.currencies || {})
      const markets = $_.pickBy(state.config?.markets, (market: MarketConfig) => {
        return (
          !$_.isEmpty(market) &&
          $_.isObject(market) &&
          currencies.includes(String(market?.pairs?.market))
        )
      })
      return markets
    },

    getCurrencies: (state: MainState): CurrencyList => state.config?.currencies as CurrencyList,

    getBasePrecision:
      (state: MainState) =>
      (currency: string): number =>
        state.config?.currencies?.[currency]?.precision as number,
  },
})
