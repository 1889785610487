<template>
  <nuxt-layout>
    <nuxt-page />
    <p-dialog />
  </nuxt-layout>
</template>

<script setup lang="ts">
smartRouter.init()

const mainStore = useMainStore()
mainStore.init()

definePageMeta({
  colorMode: 'system',
})
</script>
