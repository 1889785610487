<template>
  <p-modal
    width="384px"
    :modal-visibility="visibility"
    @on-close="mainStore.resetDialogAttributes()"
  >
    <div class="p-response-dialog__container gap-24">
      <p-icon icon="alert-regular" color="foreground-danger" size="xl" />
      <p
        class="f-title-20-bold t-foreground-primary"
        v-text="$t(`${mainStore.getDialogAttributes.title.langkey}`)"
      />
      <p
        class="f-body-14 t-foreground-secondary"
        v-text="$t(`${mainStore.getDialogAttributes.description.langkey}`)"
      />
      <p-button
        v-for="(item, index) in mainStore.getDialogAttributes.buttons"
        :key="index"
        button-type="ghost"
        status="success"
        @click="handleButton(item.action)"
      >
        {{ item.label.langkey }}
      </p-button>
    </div>
  </p-modal>
</template>

<script setup lang="ts">
const mainStore = useMainStore()

const visibility = computed<boolean>(() => !!mainStore.dialogAttributes?.message)
const handleButton = (action: { name: string; target: string }) => {
  if (action.name === 'close') {
    mainStore.resetDialogAttributes()
  }
}
</script>
