<template>
  <button
    v-bind="{ ...$attrs, ...(props as ButtonHTMLAttributes) }"
    class="p-button place-items-center gap-8"
    @click="$emit('on-click')"
  >
    <p-loading v-if="props.loading" :color="loadingColor" />
    <slot v-else />
  </button>
</template>

<script lang="ts" setup>
import type { ButtonHTMLAttributes } from 'vue'

defineEmits(['on-click'])

const props = defineProps({
  size: {
    type: String,
    default: 'md',
    validator: (val: string) => ['sm', 'md', 'lg'].includes(val),
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  buttonType: {
    type: String,
    default: 'filled',
    validator: (val: string) => ['filled', 'ghost', 'text'].includes(val),
  },
  status: {
    type: String,
    default: 'success',
    validator: (val: string) => ['success', 'danger'].includes(val),
  },
  rounded: {
    type: Boolean,
    default: false,
  },
})

const loadingColor = computed(() => {
  const colors = {
    filled: 'foreground-fix-white',
    ghost: props.status === 'success' ? 'foreground-primary' : 'foreground-danger',
  } as { [key: string]: string }

  return colors[props.buttonType]
})
</script>
