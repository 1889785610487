<template>
  <div
    class="p-overlay"
    v-bind="{ ...(overlayAttributes as HTMLAttributes) }"
    @click.self="$emit('on-close')"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

defineEmits(['on-close'])

const props = defineProps({
  overlay: {
    type: String,
    default: 'light',
  },
  overflowHidden: {
    type: Boolean,
    default: true,
  },
  position: {
    type: String,
    default: 'fixed',
  },
})

const overlayAttributes = {
  style: {
    position: props.position,
    background: `var(--overlay-gray-${props.overlay})`,
  },
}

onMounted(() => {
  if (import.meta.client && props.overflowHidden) {
    const { classList } = document.querySelector('body') as HTMLElement
    classList.add('p-overlay--overflow-hidden')
  }
})

onUnmounted(() => {
  const { classList } = document.querySelector('body') as HTMLElement
  classList.remove('p-overlay--overflow-hidden')
})
</script>
