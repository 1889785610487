<template>
  <div>
    <component :is="PResponseDialog"></component>
  </div>
</template>
<script script lang="ts"></script>

<script lang="ts">
import PResponseDialog from './PResponseDialog.vue'

export default defineComponent({
  setup() {
    return {
      PResponseDialog,
    }
  },
})
</script>
