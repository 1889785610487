import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as indexkXii3v7yMHMeta } from "/opt/buildhome/repo/pages/auth/contact-support/index.vue?macro=true";
import { default as indexXDkuNSxKaRMeta } from "/opt/buildhome/repo/pages/auth/forgot-password/index.vue?macro=true";
import { default as indexodUcM3hpgdMeta } from "/opt/buildhome/repo/pages/auth/new-password/index.vue?macro=true";
import { default as indexDOje8yFXATMeta } from "/opt/buildhome/repo/pages/auth/security-verification/index.vue?macro=true";
import { default as index8oOKunnnMCMeta } from "/opt/buildhome/repo/pages/auth/sign-in/index.vue?macro=true";
import { default as indexFFhqQ97vMkMeta } from "/opt/buildhome/repo/pages/auth/sign-up/index.vue?macro=true";
import { default as indexb1byllet4cMeta } from "/opt/buildhome/repo/pages/auth/verification/index.vue?macro=true";
import { default as indexECaJn9m6bSMeta } from "/opt/buildhome/repo/pages/donate/index.vue?macro=true";
import { default as indexCMVanMCzFOMeta } from "/opt/buildhome/repo/pages/index/index.vue?macro=true";
import { default as index1SZyy76jSaMeta } from "/opt/buildhome/repo/pages/markets/[marketName]/index.vue?macro=true";
import { default as index66F7hWW3KyMeta } from "/opt/buildhome/repo/pages/markets/index.vue?macro=true";
import { default as indexhEXjY4RWvEMeta } from "/opt/buildhome/repo/pages/overlayContent/index.vue?macro=true";
import { default as index6eeNabNZ2SMeta } from "/opt/buildhome/repo/pages/wallet/index.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    component: () => import("/opt/buildhome/repo/pages/account/index.vue")
  },
  {
    name: "auth-contact-support",
    path: "/auth/contact-support",
    meta: indexkXii3v7yMHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/contact-support/index.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: indexXDkuNSxKaRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/forgot-password/index.vue")
  },
  {
    name: "auth-new-password",
    path: "/auth/new-password",
    meta: indexodUcM3hpgdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/new-password/index.vue")
  },
  {
    name: "auth-security-verification",
    path: "/auth/security-verification",
    meta: indexDOje8yFXATMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/security-verification/index.vue")
  },
  {
    name: "auth-sign-in",
    path: "/auth/sign-in",
    meta: index8oOKunnnMCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/sign-in/index.vue")
  },
  {
    name: "auth-sign-up",
    path: "/auth/sign-up",
    meta: indexFFhqQ97vMkMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/sign-up/index.vue")
  },
  {
    name: "auth-verification",
    path: "/auth/verification",
    meta: indexb1byllet4cMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/verification/index.vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/opt/buildhome/repo/pages/donate/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index/index.vue")
  },
  {
    name: "markets-marketName",
    path: "/markets/:marketName()",
    component: () => import("/opt/buildhome/repo/pages/markets/[marketName]/index.vue")
  },
  {
    name: "markets",
    path: "/markets",
    component: () => import("/opt/buildhome/repo/pages/markets/index.vue")
  },
  {
    name: "overlayContent",
    path: "/overlayContent",
    component: () => import("/opt/buildhome/repo/pages/overlayContent/index.vue")
  },
  {
    name: "wallet",
    path: "/wallet",
    component: () => import("/opt/buildhome/repo/pages/wallet/index.vue")
  }
]