import { useUserStore } from '#imports'

export default defineNuxtRouteMiddleware(() => {
  if (import.meta.client) {
    const userStore = useUserStore()
    const token = localStorage.getItem('auth-token')
    if (token) {
      userStore.addAuthToken(token)
    }
  }
})
