const passwordFormat = (value: string): boolean =>
  /(?=.*\d)(?=.*[a-z-ığüşöç])(?=.*[A-Z-İĞÜŞÖÇ]).{8,}/.test(value)

const currency = (value: string): boolean => /\d+\.?\d*/.test(value)

const nameMinWord = (value: string): boolean =>
  /^([a-zığüşöçA-ZİĞÜŞÖÇ]{2,}\s{1,5}){1,3}[a-zığüşöçA-ZİĞÜŞÖÇ]{2,}$/g.test(value)

const validDate = (value: string): boolean => {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/
  if (value.match(regex) === null) {
    return false
  }

  const [day, month, year]: any[] = value.split('/')
  const isoFormattedStr = `${year}-${month}-${day}`
  const date = new Date(isoFormattedStr)
  const timestamp = date.getTime()

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false
  }
  return date.toISOString().startsWith(isoFormattedStr)
}

const validDateByToday = (value: string): boolean => {
  const now = Number(new Date().toISOString().slice(0, 10).replace(/-/g, ''))
  const [day, month, year]: any[] = value.split('/')

  return now > year * 10000 + month * 100 + day * 1
}

const over18 = (value: string): boolean => {
  const now = Number(new Date().toISOString().slice(0, 10).replace(/-/g, ''))
  const [day, month, year]: any[] = value.split('/')
  const dob: number = year * 10000 + month * 100 + day * 1
  return now - dob >= 180000
}

const under90 = (value: string): boolean => {
  const now = Number(new Date().toISOString().slice(0, 10).replace(/-/g, ''))
  const [day, month, year]: any[] = value.split('/')
  const dob: number = year * 10000 + month * 100 + day * 1
  return now - dob <= 900000
}

const validIban = (value: string): boolean | any => {
  return value.toString().replace(/\D/g, '').length === 24
}

const validPhoneNumber = (value: string): boolean => {
  return value.replace(/\D/g, '').length >= 5 && value.replace(/\D/g, '').length <= 15
}

const validIdentity = (value: string): boolean => {
  return value.length === 11
}

const dateBetween = (value: string, params: string[]): boolean => {
  const [min, max] = params
  const date = new Date(value.split('/').reverse().join('-'))
  return date >= new Date(min) && date <= new Date(max)
}

const unsufficientBalance = (value: number, options: Record<string, any>): boolean => {
  return options?.amount <= options?.balance
}

const maxWithdrawAmount = (value: number, options: Record<string, any>): boolean => {
  return Number(options?.num) <= Number(options?.max)
}

const minWithdrawAmount = (value: number, options: Record<string, any>): boolean => {
  return options?.amount >= options?.minAmount
}

const minLengthWithLabel = (value: string, options: Record<string, any>): boolean => {
  return value?.length >= options?.length
}

const maxLengthWithLabel = (value: string, options: Record<string, any>): boolean => {
  return value?.length < options?.length
}

const maxAvailableAmount = (value: number, options: Record<string, any>): boolean => {
  return Number(options?.num) <= Number(options?.max)
}

const balanceSuffuciencyPresale = (value: number, options: Record<string, any>): boolean => {
  return Number(options?.num) <= Number(options?.max)
}

const minAmountPresale = (value: number, options: Record<string, any>): boolean => {
  return Number(options?.num) >= Number(options?.min)
}

const minTotalPresale = (value: number, options: Record<string, any>): boolean => {
  return Number(options?.num) >= Number(options?.min)
}

const maxAmountPresale = (value: number, options: Record<string, any>): boolean => {
  return Number(options?.num) <= Number(options?.max)
}

const maxTotalPresale = (value: number, options: Record<string, any>): boolean => {
  return Number(options?.num) <= Number(options?.max)
}

const remainingPresaleAmount = (value: number, options: Record<string, any>): boolean => {
  return Number(options?.num) <= Number(options?.max)
}

const remainingPresaleTotal = (value: number, options: Record<string, any>): boolean => {
  return Number(options?.num) <= Number(options?.max)
}

const totalMoreThanZero = (value: number, options: Record<string, any>): boolean => {
  return Number(options?.num) > 0
}

const amountMoreThanZero = (value: number, options: Record<string, any>): boolean => {
  return Number(options?.num) > 0
}

const presaleSoldOut = (value: number, options: Record<string, any>): boolean => {
  return Number(options?.num) > 0
}

const requiredGroup = (value: string | number): boolean => {
  return !!String(value).length
}

export {
  over18,
  under90,
  currency,
  validIban,
  validDate,
  nameMinWord,
  dateBetween,
  validIdentity,
  presaleSoldOut,
  passwordFormat,
  minTotalPresale,
  maxTotalPresale,
  validDateByToday,
  maxAmountPresale,
  minAmountPresale,
  validPhoneNumber,
  maxWithdrawAmount,
  minWithdrawAmount,
  totalMoreThanZero,
  amountMoreThanZero,
  maxAvailableAmount,
  minLengthWithLabel,
  maxLengthWithLabel,
  unsufficientBalance,
  remainingPresaleAmount,
  remainingPresaleTotal,
  balanceSuffuciencyPresale,
  requiredGroup,
}
