import {
  useRouter,
  useRoute,
  type Router,
  type RouteLocationNormalizedLoaded,
  type RouteLocationRaw,
} from 'vue-router'

export const smartRouter = {
  router: {} as Router,
  route: {} as RouteLocationNormalizedLoaded,
  firstUrl: '' as string,
  firstPosition: 0 as number,

  init(): void {
    this.router = useRouter()
    this.route = useRoute()

    if (this.route.query?.overlay) {
      this.firstUrl = this.route.path
      this.firstPosition = -1
    } else if (this.route.fullPath.includes('auth')) {
      this.firstUrl = '/'
      this.firstPosition = -1
    }

    this.router.beforeEach(() => {
      const { back, current, position } = window.history.state

      if (current.includes('overlay') && back && !back.includes('overlay')) {
        this.firstPosition = Number(position) - 1
      }
    })
  },

  go(route: RouteLocationRaw) {
    this.firstPosition = this.firstPosition ? this.firstPosition : window.history.state.position
    this.firstUrl = this.firstUrl ? this.firstUrl : this.route.path
    this.router.push(route)
  },

  back(): void {
    if (this.firstPosition === -1) {
      this.router.replace(this.firstUrl)
    } else {
      this.router.back()
    }
  },

  exit(): void {
    const goBackPosition = window.history.state.position - this.firstPosition
    if (this.firstPosition === -1) {
      this.router.replace(this.firstUrl)
    } else {
      this.router.go(-1 * goBackPosition)
    }

    this.firstUrl = ''
    this.firstPosition = 0
  },
}
