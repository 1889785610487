<template>
  <div class="place-items-center">
    <p-icon icon="loading" v-bind="loadingAttributes" />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  color: {
    type: String,
    default: 'foreground-accent',
    validator: (val: string) =>
      [
        'foreground-accent',
        'foreground-fix-white',
        'foreground-primary',
        'foreground-danger',
      ].includes(val),
  },
  size: {
    type: String,
    default: 'sm',
    validator: (val: string) => ['sm', 'md', 'lg'].includes(val),
  },
})

const loadingAttributes = computed((): Record<string, string> => {
  return {
    color: props.color,
    size: props.size,
    class: 'p-loading',
  }
})
</script>
