/* eslint-disable camelcase */
import { defineRule, configure } from 'vee-validate'
import {
  max,
  min,
  email,
  regex,
  length,
  numeric,
  between,
  required,
  alpha_num,
  confirmed,
  alpha_spaces,
} from '@vee-validate/rules'
import * as customRules from './custom-rules'

const veeValidatePlugin = defineNuxtPlugin(() => {
  const validations = {
    min,
    max,
    email,
    numeric,
    required,
    nameMax: max,
    passwordMin: min,
    otpLength: length,
    otpNumeric: numeric,
    alphaNum: alpha_num,
    alpha: alpha_spaces,
    memoTagRegex: regex,
    tradeBetween: between,
    phoneNumeric: numeric,
    presaleTerms: required,
    policyRequired: required,
    selectRequired: required,
    emailConfirmed: confirmed,
    walletAddressRegex: regex,
    destinationTagRegex: regex,
    serialNumberLength: length,
    oldSerialNumberLength: length,
    passwordConfirmed: confirmed,
    identityCardNumberLength: length,
    serialNumberMin: min,
    serialNumberMax: max,
    ...customRules,
  }

  Object.keys(validations).forEach((validation) => {
    return defineRule(validation, (validations as Record<string, any>)[validation])
  })

  configure({
    generateMessage: (context) => {
      return $t(`validation.${context.rule?.name}`, (context.rule as any).params)
    },
  })
})

export default veeValidatePlugin
