export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-out', {
    beforeMount(el, binding) {
      el.clickOutsideEvent = function (event: { target: any }) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event)
        }
      }
      document.addEventListener('click', el.clickOutsideEvent)
    },

    unmounted(el) {
      document.removeEventListener('click', el.clickOutsideEvent)
    },
  })
})
