export default {
  "p-auth-header": () => import("/opt/buildhome/repo/layouts/PAuth/Header.vue"),
  "p-auth": () => import("/opt/buildhome/repo/layouts/PAuth/PAuth.vue"),
  "p-footer-link-items": () => import("/opt/buildhome/repo/layouts/PFooter/LinkItems/index.vue"),
  "p-footer-paribu-self": () => import("/opt/buildhome/repo/layouts/PFooter/ParibuSelf/index.vue"),
  "p-footer-social-medias": () => import("/opt/buildhome/repo/layouts/PFooter/SocialMedias/index.vue"),
  "p-footer": () => import("/opt/buildhome/repo/layouts/PFooter/index.vue"),
  "p-header-mobile-navigation": () => import("/opt/buildhome/repo/layouts/PHeader/MobileNavigation/index.vue"),
  "p-header": () => import("/opt/buildhome/repo/layouts/PHeader/index.vue"),
  default: () => import("/opt/buildhome/repo/layouts/default.vue"),
  empty: () => import("/opt/buildhome/repo/layouts/empty.vue")
}